import http from '../http-common'

class UploadFilesService {
  upload(file, onUploadProgress, id) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('id', id)

    return http.post('/shoppingui/ftpfiles/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }

  delete(id, fileName) {
    const formData = new FormData()
    formData.append('fileName', fileName)
    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/delete', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  getFiles(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getFilesa0(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getFilesa1(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getFilesa2(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getFilesa301(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getFilesa302(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getFilesa303(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getFilesa304(id) {
    const formData = new FormData()

    formData.append('id', id)
    return http.post('/shoppingui/ftpfiles/loadAll', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  getList() {
    return http.get('/shoppingui/ectargettype/list')
  }
}

export default new UploadFilesService();
