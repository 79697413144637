import axios from 'axios';

export default axios.create({
  // 测试用
  // baseURL: 'http://39.105.156.6:3999/api/',
  // 生成https
  baseURL: 'https://www.cnassets.com/api/',
  // baseURL: 'http://192.168.1.3:3999/api/',
  // 本地用
  // baseURL: 'http://127.0.0.1:58080/',
  headers: {
    'Content-type': 'application/json'
  }
});
